body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f9fb;
  font-size: 16px;
  color: #3E4348;
}

*, *:before, *:after {
  box-sizing: border-box;
}

body, textarea, input, select, button {
  font-family: 'Quicksand';
}

select {
  width: 50%;
}

input[type="text"], input[type="password"], input[type="file"], select, textarea {
  width: 70%;
  padding: 14px;
  font-size: 1.15em;
  padding: 10px;
  background-color: rgb(243, 246, 249);
  border-radius: 5px;
  border: none;
}

textarea {
  width: 96%;
  padding: 10px;
}

select {
  border: none;
}

.Center {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.Error {
  font-weight: bold;
  color: #dc3545;
}

a {
  color: white;
}

.Container {
  background-color: white;
   padding: 2% 5%;
}

.Secondary {
  background-color: #007bff !important;
  color: white !important;
}

.Destructive-Button {
  background-color: #dc3545 !important;
  color: white !important;
}

.mandatory-field::after {
  content: ' *';
  color: #ff5454;
  font-weight: bold;
}

.rc-progress-line {
  overflow: visible;
}

.rc-progress-line-trail {
  stroke-width: 5px;
}

select {
  /* styling */
  border-radius: 5px;
  display: inline-block;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  /* reset */
  margin: 0;      
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

mark {
  padding: 2px 5px;
  border-radius: 5px;
}
