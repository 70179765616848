.Container {
  margin: 0% 10%;
}

@media  (max-width: 1366px) {
	.Container {
		margin: 0;
	}
}

