.rc-slider-mark-text {
    font-size: 12px;
    font-weight: bold;
    margin-top: 3px;
}

.rc-slider-handle {
    height: 20px !important;
    width: 20px !important;
    margin-top: -8px !important;
    border-width: 3px !important;
}

.rc-slider-dot {
    width: 11px !important;
    height: 11px !important;
    bottom: -3px !important;
}

.rc-slider {
    width: 96% !important;
    margin: auto;
}

.sliderContainer {
    width: 70%
}

.readOnlyStyle {
    margin: 0
}